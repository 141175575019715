let paAPIEndpoints = {
  //! ONBOARDING PA
  ONBOARDING_COMPANY_DETAIL: {
    endpoint: "/v2/internal/company",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_SECRET_DATA: {
    endpoint: "/v2/internal/company_secret",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_MODULE_SECRET: {
    endpoint: "/v2/internal/company_module",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_MASTER_SETUP: {
    endpoint: "/v2/banking/account/master_setup",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_PROVIDER_SECRET: {
    endpoint: "/v2/internal/company_provider_secret",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  SETTLEMENT_ACCOUNT_SETUP: {
    endpoint: "/v3/internal/banking/account/settlement_account",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  SEND_ONBOARDING_CREDENTIALS: {
    endpoint: "/v2/email_company_credentials",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  USER_REGISTER: {
    endpoint: `/user/register`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  USER_AUTH_INTERNAL: {
    endpoint: `/v2/internal/auth`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  USER_UPDATE_REGISTER: {
    endpoint: `/user/update`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  FETCH_MERCHANT_DETAILS: {
    endpoint: `/decentro/operations/fetch/merchant_category`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_CONSUMER_TYPE: {
    endpoint: `/decentro/operations/fetch/consumer_types`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_STATE_DETAILS: {
    endpoint: `/decentro/operations/fetch/states`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  GOOGLE_LOGIN: {
    endpoint: `/user/internal/auth`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  MERCHANT_ONBOARDING_DMO: {
    endpoint: "/v3/internal/payments/merchant_onboarding",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  PAYMENT_CHANNEL_SETUP: {
    endpoint: "/v3/internal/payments/manage_payment_channels",
    // baseUrl: process.env.REACT_APP_READ_API_ENDPOINT_BASE,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  MULTI_ATTEMPT: {
    endpoint: "/v2/common/internal/config/provider_company_param",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  VPA: {
    endpoint: "/v3/internal/payments/vpa/add",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  //! ONBOARDING PA Ends
  COMPANY_MODULE_PROVIDER: {
    endpoint: "/v2/internal/module_provider",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_MODULES: {
    endpoint: "/dashboard/modules",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_MODULES_LATEST: {
    endpoint: "/decentro/read/fetch/module_details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  PLAN_TYPE: {
    endpoint: "/v2/internal/plan_type",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  PLAN_PRICING_TYPE: {
    endpoint: "/v2/internal/plan_pricing_type",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_API_METER: {
    endpoint: "/v2/internal/company_api_meter",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_API_METER_SALAD: {
    endpoint: "/decentro/read/fetch/company_api_meter",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CREATE_VA: {
    endpoint: "/v2/banking/account/virtual",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  ADD_DUMMY_MONEY: {
    endpoint: "/v2/internal/transaction/record",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  ACCOUNT_BALANCE: {
    endpoint: `/v2/banking/account`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GET_ALL_COMPANY_DETAIL: {
    endpoint: `/v2/internal/company`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  // LA MIN BALANCE
  COMPANY_BASED_PROVIDER_FETCH: {
    endpoint: "/decentro/operations/fetch/company/providers",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  COMPANY_BASED_CONSUMER_OPTIONS: {
    endpoint: "/decentro/operations/fetch/consumer_urn",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CONFIGURE_MVA: {
    endpoint: `/v2/banking/account/master_setup`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GET_HTTP_METHOD_TYPE: {
    endpoint: `/decentro/read/fetch/http_method_type`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  // Merge conflict arrives here
  GET_COMPANY_AND_IP_DETAILS: {
    endpoint: "/decentro/read/fetch/company/customer_details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  GET_COMPANY_IP_DETAILS: {
    endpoint: "/decentro/read/fetch/ip/whitelisted",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  COMPANY_IP_ADDRESS_PUT: {
    endpoint: "/v2/internal/company/ip_address",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GET_CALLBACK_DETAILS: {
    endpoint: `/v2/company/callback`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GET_CALLBACK_TYPE: {
    endpoint: `/decentro/operations/company/callbacks`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  ADD_NEW_CALLBACK: {
    endpoint: `/v2/company/callback`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GET_USER_TYPE: {
    endpoint: `/decentro/operations/fetch/user_type`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  GET_DASHBOARD_USERS: {
    endpoint: `/decentro/operations/fetch/dashboard_users`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  MASTER_SEARCH_TRANSACTION: {
    endpoint: `/decentro/read/fetch/master/transaction`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  GET_CALLBACK_VALUES: {
    endpoint: `/decentro/operations/fetch/provider/callback/data`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  BANKING_ACCOUNT: {
    endpoint: `/v2/banking/account`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  AVERAGE_BANKING_ACCOUNT: {
    endpoint: `/decentro/read/account/balance/average`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CALLBACK_HTTP_METHOD_TYPE: {
    endpoint: `/decentro/read/fetch/http_method_type`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CALLBACK_TYPE: {
    endpoint: `/decentro/read/fetch/callback_type`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_VA: {
    endpoint: `/decentro/operations/search/virtual_account`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  GENERATE_TOTP: {
    endpoint: "/v2/user/login/admin",
    baseUrl: process.env.REACT_APP_EXC_API_ENDPOINT,
  },
  VERIFY_TOTP: {
    endpoint: "/v2/user/mfa/verify",
    baseUrl: process.env.REACT_APP_EXC_API_ENDPOINT,
  },
  // GENERATE_TOTP: {
  //   endpoint: `/v2/auth/user/otp`,
  //   baseUrl: process.env.REACT_APP_API_ENDPOINT,
  // },
  // VERIFY_TOTP: {
  //   endpoint: `/v2/auth/user/otp/verify`,
  //   baseUrl: process.env.REACT_APP_API_ENDPOINT,
  // },
  MASTER_ACCOUNTS_ALIAS: {
    endpoint: `/decentro/read/fetch/dropdown/master_account_alias`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  GET_COMPANY_COMMON_NAME_LIST: {
    endpoint: `/decentro/read/fetch/company_list`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  INITIATE_TRANSACTION_REVERSAL: {
    endpoint: `/internal/core_banking/money_transfer/initiate_reversal`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  MARK_TRANSACTION_SUCCESS: {
    endpoint: `/dashboard/admin/internal/transaction`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  RECORD_INCOMING_TRANSACTION: {
    endpoint: `/v2/internal/transaction/record`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  BULK_ACTION: {
    endpoint: `/v2/common/general_purpose_batch_job`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_PROVIDERS_LIST: {
    endpoint: `/decentro/read/fetch/providers`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_COMPANY_DETAILS: {
    endpoint: "/decentro/operations/fetch/company_details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_IP_DETAILS: {
    endpoint: "/decentro/operations/fetch/ip/whitelisted",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  REQUEST_IP_WHITELIST: {
    endpoint: "/v2/admin/request_ip_whitelist",
    baseUrl:
      process.env.REACT_APP_ENV_NAME == "qa"
        ? process.env.REACT_APP_IP_ROUTE_STAG
        : process.env.REACT_APP_API_ENDPOINT,
  },
  // LA MIN BALANCE
  LA_MIN_BALANCE: {
    endpoint: "/v3/internal/banking/minimum_balance",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_CHANNEL_SETUP: {
    endpoint: "/v2/common/internal/company_channel_template",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  // CREDITS MANAGEMENT
  FETCH_CREDITS_DETAILS: {
    endpoint: "/decentro/operations/fetch/credits_by_company_id",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
};

export default paAPIEndpoints;
