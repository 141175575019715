import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
// api-config
import { APIConfig } from "../../../../services/apiConfiguration";
import paAPIEndpoints from "../../../../config/pa_api_endpoints/manage_customer_pa/endpoint";
// utils
import { randomUUID } from "../../../../services/randomUUID";
// component imports
import SnackbarMessage from "../../../SnackbarMessage/SnackbarMessage";
import ModulesList from "../ModulesList/ModulesList";
import { TotpModal } from "../../../ReTriggerCredentials/TotpModal/TotpModal";
// context-imports
import CreditManagementContext from "../../../../contexts/CreditManagementContext";
// styles imports
import "./AvailableModulesList.scss";

const AvailableModulesList = () => {
  //^ context
  const { editCreditsApi, selectedCompany } = useContext(
    CreditManagementContext
  );

  const [subscribedModules, setSubscribedModules] = useState("");
  //  to disable submit button
  const [isDisabled, setIsDisabled] = useState(true);

  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // TOTP
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  const [qrBase, setQrBase] = useState("");
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  useEffect(() => {
    let editDetail;
    if (!!subscribedModules) {
      editDetail = subscribedModules.filter((item) => {
        return (
          !!item.module_code &&
          !!item.max_usable_credits &&
          item.creditInputError === false
        );
      });

      if (editDetail.length > 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [subscribedModules]);

  const submitEditCreditDetails = () => {
    const editDetail = subscribedModules.filter((item) => {
      return (
        !!item.module_code &&
        !!item.max_usable_credits &&
        item.creditInputError === false
      );
    });

    if (editDetail.length === 0) {
      ReactDOM.render(
        <SnackbarMessage msgtype="Error" msg={"No Changes Found !"} />,
        document.getElementById("snackbar")
      );
    } else {
      let filteredData;

      filteredData = editDetail.map((detail) => {
        return {
          module_code: detail.module_code,
          max_usable_credits:
            detail.operationFunction === "add"
              ? detail.maxUsableCredits + +detail.max_usable_credits
              : detail.maxUsableCredits - +detail.max_usable_credits,
        };
      });

      const payload = {
        company_id: +selectedCompany,
        api_meter_details: filteredData,
      };
      editCreditsApi(payload);
      resetEditCreditsOnSubmit();
    }
  };

  const resetEditCreditsOnSubmit = () => {
    setSubscribedModules(
      subscribedModules.map((data) => {
        return { ...data, module_code: "", max_usable_credits: "" };
      })
    );
  };

  // TOTP
  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };

    setIsLoading(true);

    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl +
        paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            totp_token: res.data.totp_token,
          }));
        }
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  return (
    <>
      {selectedCompany ? (
        <div className="available-modules-container">
          <div className="available-modules-header">
            <div className="header-container">
              <h1 className="heading"> </h1>
            </div>
            <div className="update-btn-container">
              <button
                onClick={submitHandler}
                className={`manage-credit-btn ${
                  !isDisabled && !isLoading && "active-credit-btn"
                }`}
                type="button"
                disabled={isDisabled}
              >
                {isLoading ? "Loading..." : "Update"}
              </button>
            </div>
          </div>
          <div className="credits-management-divider"></div>
          <ModulesList
            subscribedModules={subscribedModules}
            setSubscribedModules={setSubscribedModules}
          />
        </div>
      ) : (
        <div className="no-company-selected-container">
          <p>
            Please select a company from above , To load the available modules
          </p>{" "}
        </div>
      )}
      {/* TOTP */}
      {showTotpModal && (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitAfterTotp={submitEditCreditDetails}
        />
      )}
    </>
  );
};

export default AvailableModulesList;
