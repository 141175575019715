import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import axios from "axios";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import { APIConfig } from "../../../services/apiConfiguration";

//component-import
import TextFieldSelectCompanyList from "../../../UI/TextField/TextFieldSelectCompanyList";
import ManageCredits from "./ManageCredits/ManageCredits";
// context-import
import CreditManagementContext from "../../../contexts/CreditManagementContext";

//styles-import
import "../../../UI/TextField/TextField.scss";

const initialValues = {
  company_name: "",
};

const validationSchema = Yup.object({
  company_name: Yup.object().shape({
    value: Yup.number().required("Please Select an Option"),
    label: Yup.string().required("Please Select an Option"),
  }),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const CreditManagement = () => {
  //^ context
  const { setSelectedCompany, refreshModuleList, setRefreshModuleList } =
    useContext(CreditManagementContext);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {},
  });

  const getCompanyOptions = useCallback(() => {
    if (!hasMore) return; // Prevent further API calls if no more data
    setIsLoading(true);
    APIConfig.API_Client.get(
      `${paAPIEndpoints.GET_ALL_COMPANY_DETAIL.baseUrl}${paAPIEndpoints.GET_ALL_COMPANY_DETAIL.endpoint}?page=${currentPage}`,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          value: item.company_id,
          label: item.common_name,
        }));

        setCompanyOptions((prevOptions) => [...prevOptions, ...options]);
        setIsLoading(false);
        setHasMore(response.data.hasMore); // Assuming the API returns this
      })
      .catch((error) => {
        console.error("Error while fetching companies list:", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  }, [currentPage, hasMore]);

  useEffect(() => {
    getCompanyOptions();
  }, [getCompanyOptions]);

  const lastCompanyElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content ui-divider">
            <div className="ui-form-inputs-section">
              <TextFieldSelectCompanyList
                id="company_name"
                name="company_name"
                onChange={(selectedOption) => {
                  formik.setFieldValue("company_name", selectedOption);
                  setSelectedCompany(selectedOption?.value || null);
                  setRefreshModuleList(!refreshModuleList);
                }}
                onBlur={() => formik.setFieldTouched("company_name", true)}
                value={formik.values.company_name}
                options={companyOptions.map((option, index) => ({
                  ...option,
                  ref:
                    index === companyOptions.length - 1
                      ? lastCompanyElementRef
                      : null,
                }))}
                noOptionsMessage={() => "No such company exists"}
                label="Company"
                // isToolTip={"Select company to manage credits"}
                required={true}
                isLoading={isLoading}
                isClearable={false}
                placeholder="Select company name"
                isformatOptionLabel={true}
              />
            </div>
          </div>

          {/* List modules based on company id */}
          <ManageCredits />
        </div>
      </form>
    </>
  );
};

export default CreditManagement;
